import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import LoadingPage from '@compUtils/LoadingPage'
import { useAuth } from '@context/AuthContext'
import { useMobileScreen } from '@hooks'

import { Header, Sidebar, SecondaryContent, MainContent } from '@components'
import { Login, Policies, Privacy, Terms } from '@pages'

import 'react-datepicker/dist/react-datepicker.css'
import '@styles/app.css'
import '@styles/style.css'
import { Landing } from './pages'

function App() {
    const location = useLocation()
    const { user, isAuthLoading } = useAuth()

    if (isAuthLoading) {
        return <LoadingPage />
    }

    return (
        <Routes>
            {!user ? (
                <>
                    <Route path="/Policies" element={<Policies />} />
                    <Route
                        path="/Policies/privacy-policy"
                        element={<Privacy />}
                    />
                    <Route path="/Policies/terms-of-use" element={<Terms />} />
                    <Route path="/SignIn" element={<Login />} />
                    <Route path="/SignUp" element={<Login />} />
                    <Route path="/" element={<Landing />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </>
            ) : (
                <>
                    <Route path="/Policies" element={<Policies />} />
                    <Route
                        path="/Policies/privacy-policy"
                        element={<Privacy />}
                    />
                    <Route path="/Policies/terms-of-use" element={<Terms />} />
                    <Route path="/" element={<Landing />} />
                    <Route path="*" element={<AuthenticatedApp />} />
                </>
            )}
        </Routes>
    )
}

function AuthenticatedApp() {
    const isMobile = useMobileScreen()

    return (
        <>
            <Header />
            <div className="main-container">
                {!isMobile && <Sidebar />}
                <MainContent />
                <SecondaryContent />
            </div>
            {isMobile && <Sidebar />}
        </>
    )
}

export default App
