import React, { useState } from 'react'
import {
    getAuth,
    GoogleAuthProvider,
    FacebookAuthProvider,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithPopup,
    updateProfile,
} from 'firebase/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth, useAlert } from '@context'

import {
    Google,
    Facebook,
    EntireLogo,
    LockedBlack,
    LockedHidden,
} from '@assets'

import '@styles/login.css'

function Login() {
    const auth = getAuth()
    const location = useLocation()
    const navigate = useNavigate()
    const { setIsProfileUpdating, setIsAuthLoading } = useAuth()
    const { showAlert } = useAlert()

    const [page, setPage] = useState(
        location.pathname === '/SignIn' ? 'login' : 'signup',
    )

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [isPasswordConfirmVisible, setIsPasswordConfirmVisible] =
        useState(false)

    const getAuthProvider = (providerType) => {
        switch (providerType) {
            case 'google':
                return new GoogleAuthProvider()
            case 'facebook':
                return new FacebookAuthProvider()
            default:
                throw new Error('Unsupported provider type')
        }
    }

    const handleThirdPartySignup = async (providerType) => {
        const provider = getAuthProvider(providerType)
        try {
            await signInWithPopup(auth, provider)
        } catch (error) {
            console.error('Error during third-party signup:', error)
        }
    }

    const handleGoogleLogin = async () => {
        await handleThirdPartySignup('google')
    }

    const handleFacebookLogin = async () => {
        await handleThirdPartySignup('facebook')
    }

    const handleEmailLogin = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password)
        } catch (error) {
            console.error('Error logging in with email:', error)
            showAlert('Email or Password incorrect', 'alert-error')
        }
    }

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/
        return re.test(email)
    }

    const validatePassword = (password) => {
        const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
        return re.test(password)
    }

    const handleEmailSignUp = async () => {
        try {
            if (!email || !password || !passwordConfirm) {
                showAlert('Please fill in all fields', 'alert-error')
                return
            }
            if (!validateEmail(email)) {
                showAlert('Please enter a valid email address', 'alert-error')
                return
            }
            if (!validatePassword(password)) {
                showAlert(
                    'Password must contain at least 8 characters, one uppercase, one lowercase and one number',
                    'alert-error',
                )
                return
            }
            if (password !== passwordConfirm) {
                showAlert('Passwords do not match', 'alert-error')
                return
            }

            setIsAuthLoading(true)
            setIsProfileUpdating(true)

            const userCredential = await createUserWithEmailAndPassword(
                auth,
                email,
                password,
            )
            const user = userCredential.user

            await updateProfile(user, {
                displayName: `${firstName} ${lastName}`,
            })

            await user.getIdToken(true)

            setIsProfileUpdating(false)

            navigate('/profile')
        } catch (error) {
            let errorMessage = error.message
            if (error.code === 'auth/email-already-in-use') {
                errorMessage = "Your email is already in use."
            }
            setIsProfileUpdating(false)
            showAlert(`${errorMessage}`, 'alert-error')
            console.error('Error signing up with email:', error)
        }
    }

    const handleResetPassword = async () => {
        try {
            if (!email) {
                showAlert('Please enter your email address', 'alert-error')
                return
            }

            await sendPasswordResetEmail(auth, email)
            showAlert('Email sent!', 'alert-success')
        } catch (error) {
            console.error('Error sending password reset email:', error)
            showAlert(
                'Failed to send password reset email. Please try again.',
                'alert-error',
            )
        }
    }

    const handleLogoClick = () => {
        navigate('/')
    }

    const handlePasswordPage = async () => {
        setPage('password')
    }

    const changeRoute = () => {
        if (page === 'login') {
            setPage('signup')
            navigate('/SignUp')
        } else {
            setPage('login')
            navigate('/SignIn')
        }
    }

    return (
        <div className="login-container">
            <div className="login-form">
                <div className="form-content">
                    <div className="cotalent-login-logo">
                        <img
                            src={EntireLogo}
                            alt="EntireLogo"
                            className="entire-logo"
                            onClick={handleLogoClick}
                        />
                        <div className="alpha">
                            <span>Alpha</span>
                        </div>
                    </div>
                    <div className="title-container">
                        <h1
                            className={`form-title ${
                                page === 'signup' ? 'visible' : 'hidden'
                            }`}
                        >
                            Sign Up
                        </h1>
                        <h1
                            className={`form-title ${
                                page === 'login' ? 'visible' : 'hidden'
                            }`}
                        >
                            Sign In
                        </h1>
                        <h1
                            className={`form-title ${
                                page === 'password' ? 'visible' : 'hidden'
                            }`}
                        >
                            Forgot Password
                        </h1>
                    </div>

                    <div className="form-buttons">
                        <div
                            className={`form-buttons-signup ${
                                page === 'signup' ? 'active' : ''
                            }`}
                        >
                            <div className="input-container">
                                <input
                                    id="first-name-input"
                                    className={`border-label-input ${
                                        !firstName && 'invalid-border'
                                    }`}
                                    value={firstName}
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                    placeholder="Given Name"
                                    required
                                />
                            </div>
                            <div className="input-container">
                                <input
                                    id="last-name-input"
                                    className={`border-label-input ${
                                        !lastName && 'invalid-border'
                                    }`}
                                    value={lastName}
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                    placeholder="Family Name"
                                    required
                                />
                            </div>
                        </div>

                        <div className="input-container">
                            <input
                                id="email-input"
                                className={`border-label-input ${
                                    !validateEmail(email) &&
                                    page === 'signup' &&
                                    'invalid-border'
                                }`}
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email Address"
                                required
                            />
                        </div>
                        <div
                            className={`form-buttons-signup ${
                                page === 'password' ? '' : 'active'
                            }`}
                        >
                            <div className="input-container">
                                <input
                                    id="password-input"
                                    className={`border-label-input ${
                                        !validatePassword(password) &&
                                        page === 'signup' &&
                                        'invalid-border'
                                    }`}
                                    type={
                                        isPasswordVisible ? 'text' : 'password'
                                    }
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    placeholder="Password"
                                    required
                                />
                                <button
                                    className="toggle-password-visibility"
                                    onClick={() =>
                                        setIsPasswordVisible(!isPasswordVisible)
                                    }
                                >
                                    {isPasswordVisible ? (
                                        <img
                                            src={LockedBlack}
                                            alt="showed password"
                                        />
                                    ) : (
                                        <img
                                            src={LockedHidden}
                                            alt="hidden password"
                                        />
                                    )}
                                </button>
                            </div>
                            {page === 'signup' && (
                                <div className="input-container">
                                    <input
                                        id="password-confirm-input"
                                        className={`border-label-input ${
                                            (!validatePassword(password) ||
                                                password !== passwordConfirm) &&
                                            'invalid-border'
                                        }`}
                                        type={
                                            isPasswordConfirmVisible
                                                ? 'text'
                                                : 'password'
                                        }
                                        value={passwordConfirm}
                                        onChange={(e) =>
                                            setPasswordConfirm(e.target.value)
                                        }
                                        placeholder="Confirm Password"
                                        required
                                    />
                                    <button
                                        className="toggle-password-visibility"
                                        onClick={() =>
                                            setIsPasswordConfirmVisible(
                                                !isPasswordConfirmVisible,
                                            )
                                        }
                                    >
                                        {isPasswordConfirmVisible ? (
                                            <img
                                                src={LockedBlack}
                                                alt="showed password"
                                            />
                                        ) : (
                                            <img
                                                src={LockedHidden}
                                                alt="hidden password"
                                            />
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <button
                        onClick={
                            page === 'signup'
                                ? handleEmailSignUp
                                : page === 'login'
                                  ? handleEmailLogin
                                  : handleResetPassword
                        }
                        className="btn-email"
                    >
                        {page === 'signup'
                            ? 'Sign Up'
                            : page === 'login'
                              ? 'Sign In'
                              : 'Reset Password'}
                    </button>
                    <p>
                        {page === 'login'
                            ? "Don't have an account ? "
                            : 'Already have an account ? '}
                        <span onClick={changeRoute} className="sign-in-link">
                            {page === 'login' ? 'Sign Up' : 'Sign In'}
                        </span>
                    </p>
                    <p className={`forgot-password ${page}`}>
                        Forgot your password ?{' '}
                        <span
                            onClick={handlePasswordPage}
                            className={`sign-in-link ${
                                page !== 'login' && 'hidden'
                            }`}
                        >
                            Click Here
                        </span>
                    </p>

                    <div className="or-section">
                        <span>OR</span>
                    </div>

                    <div className="social-buttons">
                        <button
                            onClick={handleGoogleLogin}
                            className="btn-google"
                        >
                            <img
                                src={Google}
                                alt="Google Icon"
                                className="icon"
                            />
                            Sign {page === 'signup' ? 'up' : 'in'} with Google
                        </button>
                        <button
                            onClick={handleFacebookLogin}
                            className="btn-facebook"
                        >
                            <img
                                src={Facebook}
                                alt="Facebook Icon"
                                className="icon"
                            />
                            Sign {page === 'signup' ? 'up' : 'in'} with Facebook
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
