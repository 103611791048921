import React, { useEffect, useState, useRef, useMemo } from 'react'
import { toast } from 'react-toastify'
import * as d3 from 'd3'
import { Modal } from '@modals'
import { resetCV, undoUploadCV, undoFinalized, exportUsersGS, exportUsersJsonToGD } from '@utils'
import { useCV } from '@context'

import PromptModal from '@modals/PromptModal'

import '@styles/admin.css'

function Admin() {
    const { updateCV } = useCV()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modal, setModal] = useState(null)
    const [svgWidth, setSvgWidth] = useState(0)
    const svgRef = useRef()

    const handleNodeClick = (nodeId) => {
        switch (nodeId) {
            case '2':
                setModal('extractExperiences-prompt')
                break
            case '3':
                setModal('extractEducations-prompt')
                break
            case '4':
                setModal('enhanceCompany-prompt')
                break
            case '5':
                setModal('enhanceExperience-prompt')
                break
            case '7':
                setModal('summary-prompt')
                break
            default:
                return
        }
        setIsModalOpen(true)
    }

    const nodes = useMemo(
        () => [
            { id: '1', label: 'CV Upload', x: svgWidth / 2, y: 100 },
            {
                id: '2',
                label: 'Extract Experience & Company',
                x: svgWidth / 4,
                y: 200,
            },
            {
                id: '3',
                label: 'Extract Education & Language',
                x: (svgWidth * 2) / 3,
                y: 200,
            },
            { id: '4', label: 'Enhance Company', x: svgWidth / 15, y: 300 },
            {
                id: '5',
                label: 'Enhance Experience',
                x: (svgWidth * 1) / 3,
                y: 300,
            },
            { id: '6', label: 'CV parsing finished', x: svgWidth / 2, y: 400 },
            { id: '7', label: 'Summary Creation', x: svgWidth / 2, y: 500 },
        ],
        [svgWidth],
    )

    const redNodes = useMemo(() => ['1', '6'], [])

    const links = useMemo(
        () => [
            { source: '1', target: '2' },
            { source: '1', target: '3' },
            { source: '2', target: '4' },
            { source: '2', target: '5' },
            { source: '4', target: '6' },
            { source: '5', target: '6' },
            { source: '3', target: '6' },
        ],
        [],
    )

    useEffect(() => {
        const svg = d3.select(svgRef.current)
        const width = svg.node().getBoundingClientRect().width
        setSvgWidth(width)
        svg.selectAll('*').remove() // Clear SVG content before redraw

        // Create links
        svg.selectAll('.link')
            .data(links)
            .enter()
            .append('line')
            .attr('class', 'link')
            .attr('stroke', 'black')
            .attr('x1', (d) => nodes.find((n) => n.id === d.source).x)
            .attr('y1', (d) => nodes.find((n) => n.id === d.source).y)
            .attr('x2', (d) => nodes.find((n) => n.id === d.target).x)
            .attr('y2', (d) => nodes.find((n) => n.id === d.target).y)

        // Create nodes
        svg.selectAll('.node')
            .data(nodes)
            .enter()
            .append('circle')
            .attr('class', 'node')
            .attr('r', 10)
            .attr('fill', (d) => (redNodes.includes(d.id) ? 'black' : 'blue'))
            .attr('cx', (d) => d.x)
            .attr('cy', (d) => d.y)
            .on('click', (event, d) => handleNodeClick(d.id))

        svg.selectAll('.label')
            .data(nodes)
            .enter()
            .append('text')
            .attr('class', 'label')
            .attr('x', (d) => d.x + 15)
            .attr('y', (d) => d.y + 5)
            .text((d) => d.label)
            .attr('font-size', '15px')
            .attr('fill', 'black')

        // Ajoutez ici la logique pour positionner les nœuds verticalement
    }, [nodes, links, redNodes])

    const handleReset = async () => {
        try {
            const cv = await resetCV()
            updateCV(cv)
            toast.success('Data reset successfully')
        } catch (error) {
            toast.error('Error resetting data')
        }
    }

    const handleUndoFinalize = async () => {
        try {
            const cv = await undoFinalized()
            updateCV(cv)
            toast.success('Finalize undone successfully')
        } catch (error) {
            toast.error('Error undoing finalize')
        }
    }

    const handleUndoCVUpload = async () => {
        try {
            const cv = await undoUploadCV()
            updateCV(cv)
            toast.success('CV upload undone successfully')
        } catch (error) {
            toast.error('Error undoing CV upload')
        }
    }

    const handleExportUsers = async () => {
        try {
            await exportUsersGS()
            toast.success('Users exported successfully')
        } catch (error) {
            toast.error('Error exporting users')
        }
    }

    const handleExportUsersJson = async () => {
        try {
            await exportUsersJsonToGD()
            toast.success('Users exported successfully')
        } catch (error) {
            toast.error('Error exporting users')
        }
    }

    return (
        <div className="admin-page">
            <svg ref={svgRef} width={'100%'} height={'100%'}></svg>
            <div className="admin-buttons">
                <button onClick={handleReset}>Reset Data</button>
                <button onClick={handleUndoCVUpload}>Undo CV Upload</button>
                <button onClick={handleUndoFinalize}>Undo Finalize</button>
                <button onClick={handleExportUsers}>Export Users</button>
                <button onClick={handleExportUsersJson}>Export Users (JSON)</button>
            </div>

            <Modal
                modal="PromptModal"
                initialValue={modal}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                className="modal-content wide"
                Component={PromptModal}
            />
        </div>
    )
}

export default Admin
