import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useMemo,
    useCallback,
} from 'react'
import { useQuery, useMutation } from 'react-query'
import { useAuth } from './AuthContext'
import { useUserData } from './UserDataContext'
import {
    updateCVOnServer,
    fetchCVData,
    debouncedMutate,
    hasValidInfo,
    hasValidPref,
    checkExperiences,
    checkLanguagesAndEducation,
} from '@utils'

const CVContext = createContext()

export const useCV = () => {
    return useContext(CVContext)
}

const getStep = (userData, cvData) => {
    if (!userData || !cvData) {
        return {
            start: false,
            information: false,
            preference: false,
            finalize: false,
        }
    }
    const information = hasValidInfo(userData, cvData)
    const preference = information && hasValidPref(cvData)
    const finalize = preference
    //  &&
    // checkExperiences(cvData) &&
    // checkLanguagesAndEducation(cvData)
    return {
        start: true,
        information: information,
        preference: preference,
        finalize: finalize,
    }
}

export const CVProvider = ({ children }) => {
    const [cvData, setCVData] = useState(null)
    const { userData } = useUserData()
    const { socket, isAuthLoading, user } = useAuth()
    const [accessibleSteps, setAccessibleSteps] = useState(
        getStep(userData, cvData),
    )

    const { isLoading, isSuccess } = useQuery('fetchCV', () => fetchCVData(), {
        enabled: !isAuthLoading && !!user,
        onSuccess: (data) => {
            setCVData(data)
        },
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 30,
        retry: 3,
        retryDelay: 1000,
    })

    const { mutate } = useMutation(updateCVOnServer, {
        onSuccess: (data) => {
            if (process.env.REACT_APP_NODE_ENV === 'development') {
                // console.log('CV updated successfully', data)
            }
        },
        onError: (error) => {
            if (process.env.REACT_APP_NODE_ENV === 'development') {
                console.error('Error updating CV:', error)
            }
        },
    })

    const updateCV = useCallback(
        (newCVData) => {
            setCVData((prevData) => {
                const updatedData =
                    typeof newCVData === 'function'
                        ? newCVData(prevData)
                        : newCVData
                return updatedData
            })
        },
        [setCVData],
    )

    const updateCVSync = useCallback(
        (newCVData) => {
            return new Promise((resolve, reject) => {
                setCVData((prevData) => {
                    const updatedData =
                        typeof newCVData === 'function'
                            ? newCVData(prevData)
                            : newCVData
                    return updatedData
                })
                mutate(newCVData, {
                    onSuccess: () => {
                        resolve()
                    },
                    onError: (error) => {
                        reject(error)
                    },
                })
            })
        },
        [mutate, setCVData],
    )

    useEffect(() => {
        if (cvData) {
            debouncedMutate(mutate, cvData)
            setAccessibleSteps(getStep(userData, cvData))
        }
    }, [cvData, mutate, userData])

    useEffect(() => {
        if (socket) {
            socket.on('sck-created', (userCV) => {
                updateCV((prevCV) => {
                    return { ...prevCV, ...userCV }
                })
            })

            return () => {
                socket.off('sck-created')
            }
        }
    }, [socket, updateCV])

    const value = useMemo(
        () => ({
            cvData,
            setCVData,
            updateCV,
            updateCVSync,
            accessibleSteps,
            isLoading: isLoading || !isSuccess || !accessibleSteps.start,
        }),
        [
            cvData,
            setCVData,
            isLoading,
            isSuccess,
            accessibleSteps,
            updateCV,
            updateCVSync,
        ],
    )

    return <CVContext.Provider value={value}>{children}</CVContext.Provider>
}
