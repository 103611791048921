import { createContext, useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { authInstance } from '@config/firebase-config'
import { useUserData } from './UserDataContext'
import { io } from 'socket.io-client'
import axios from 'axios'
import { fetchUserData, refreshAuthToken } from '@utils'
import { useQuery, useQueryClient } from 'react-query'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [isProfileUpdating, setIsProfileUpdating] = useState(false)
    const [isAuthLoading, setIsAuthLoading] = useState(true)
    const { updateUserData } = useUserData()
    const [socket, setSocket] = useState(null)
    const [token, setToken] = useState(null)
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(
            authInstance,
            async (currentUser) => {
                if (isProfileUpdating) return
                setUser(currentUser)
                if (currentUser) {
                    try {
                        const token = await currentUser.getIdToken()
                        setToken(token)
                        axios.defaults.headers.common['Authorization'] =
                            `Bearer ${token}`

                        queryClient.invalidateQueries('userData')
                    } catch (error) {
                        console.error('Error while updating user data:', error)
                        await logout()
                    }
                } else {
                    setToken(null)
                    setUser(null)
                    setSocket(null)
                    updateUserData(null)
                    queryClient.clear()
                    setIsAuthLoading(false)
                }
            },
        )

        return () => unsubscribe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isProfileUpdating])

    useEffect(() => {
        async function connectSocket() {
            if (!user || !token || socket) return

            const newSocket = io(process.env.REACT_APP_BACK_URL, {
                query: { token },
                transports: ['websocket'],
                reconnection: true,
                reconnectionAttempts: Infinity,
                reconnectionDelayMax: 10000,
            })

            // Log socket
            newSocket.on('log', (message) => {
                if (process.env.REACT_APP_NODE_ENV === 'development')
                    console.log('Received log from server:', message)
            })

            // Error socket, refresh token
            newSocket.on('token-error', async () => {
                const refreshedToken = await user.getIdToken(true)
                setToken(refreshedToken)
            })

            newSocket.on('disconnect', (reason) => {
                console.log(`Socket disconnected: ${reason}`)
            })

            setSocket(newSocket)
        }

        if (!socket) {
            connectSocket()
        }

        return () => {
            if (socket) {
                socket.disconnect()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, token, socket])

    useEffect(() => {
        if (!token) return
        const axiosResponseInterceptor = axios.interceptors.response.use(
            (response) => response,
            async (error) => {
                if (error.response?.status === 401 && !error.config._retry) {
                    error.config._retry = true
                    try {
                        await refreshAuthToken()
                        error.config.headers['Authorization'] =
                            axios.defaults.headers.common['Authorization']
                        return axios(error.config)
                    } catch (refreshError) {
                        console.error('Error refreshing token:', refreshError)
                        await logout()
                        return Promise.reject(refreshError)
                    }
                }
                return Promise.reject(error)
            },
        )

        return () => {
            axios.interceptors.response.eject(axiosResponseInterceptor)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, setToken, navigate])

    const logout = async () => {
        await signOut(authInstance)
        setToken(null)
        setUser(null)
        setSocket(null)
        queryClient.clear()
        navigate('/')
    }

    useQuery('userData', fetchUserData, {
        enabled: !!user && !!token,
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 30,
        onSuccess: (data) => {
            updateUserData(data)
            setIsAuthLoading(false)
        },
        onError: (error) => {
            console.error('Error fetching user data:', error)
            logout()
        },
    })

    return (
        <AuthContext.Provider
            value={{
                user,
                token,
                socket,
                logout,
                isAuthLoading: isAuthLoading || isProfileUpdating,
                setIsAuthLoading,
                setIsProfileUpdating,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}
